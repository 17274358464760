import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import ProgressBar from "./ProgressBar";

import { Link, useHistory } from "react-router-dom";

import BancoService from "../services/BancoService";

import { store } from "react-notifications-component";
import "animate.css";
import { ToastNotification, notificationTopRight } from "./ToastNotification";

import { ReactComponent as PowerIcon } from "../icons/power-icon.svg";
import { ReactComponent as GiftIcon } from "../icons/gift.svg";

import { ClickOutsideListener } from "react-click-outside-listener";

import Select from "react-select";
import UsuarioService from "../services/UsuarioService";

const PerfilMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 58px;
  right: 0;
  width: auto;
  min-width: 240px;
  background: ${(props) => props.theme.background};
  border-radius: 4px;
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.5);
  align-items: stretch;
  justify-content: flex-start;
  color: ${(props) => props.theme.grayDark};

  .uso {
    padding: 0 0rem;
    color: ${(props) => props.theme.text};
    font-size: 12px;
    margin-top: 1rem;

    span {
      display: block;
      margin-top: 0.25rem;
      font-size: 14px;
      color: ${(props) => props.theme.grayDark};
      text-align: right;
    }
  }

  p.user {
    padding: 0.5rem 1rem;
    font-size: 1.125rem;
  }

  .uso-arquivos {
    margin: 1rem 1rem 0.75rem 1rem;
    font-size: 1rem;

    font-weight: 600;
  }

  a {
    color: ${(props) => props.theme.grayDark};
    padding: 0.5rem 1rem;
  }

  button:last-child {
    justify-self: flex-end;
    margin-top: 1rem;
    padding: 0.75rem;
    background: ${(props) => props.theme.background};
    font-size: 1rem;
    text-align: left;
    border: none;
    border-top: 1px solid ${(props) => props.theme.grayLight};
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    color: ${(props) => props.theme.grayDark};

    svg.icon {
      margin-right: 0.5rem;
    }

    &:hover {
      background: ${(props) => props.theme.grayLight};
      color: ${(props) => props.theme.dangerDark};
    }
  }
`;

const Banco = styled.div``;

const Armazenamento = styled.div`
  border-top: 1px solid ${(props) => props.theme.grayLight};
  padding: 1rem;
  margin-top: 1rem;

  h5 {
    font-weight: 600;
    font-size: 20px;

    svg {
      transform: translateY(2px);
      margin-left: 8px;
    }
  }

  .select {
    /* width: 320px; */
    width: 100%;
    margin: 1rem 0 0;
  }

  a.indique {
    margin: 1rem 0 0;
    padding: 0;
    display: inline-block;
  }
`;

function TopnavPerfilMenu(props) {
  const [usoStorage, setUsoStorage] = useState(0);
  const [tamanhoStorage, setTamanhoStorage] = useState(1);
  const [usoBanco, setUsoBanco] = useState(0);
  const [tamanhoBanco, setTamanhoBanco] = useState(1);
  const [carregando, setCarregando] = useState(true);

  const [selectedOption, setSelectedOption] = useState("");
  const [bancos, setBancos] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    //console.log("ûpdatou");
    // BancoService.getOcupacaoMaximaStorage(props.tokenLogin)
    //   .then((resp) => {
    //     //console.log(resp);
    //     setTamanhoStorage(resp.data.capacidade_storage.toFixed(0));
    //     // //console.log(resp.data);
    //   })
    //   .catch((err) => console.log(err));

    // BancoService.getOcupacaoMaximaBanco(props.tokenLogin)
    //   .then((resp) => {
    //     setTamanhoBanco(resp.data.capacidade_db.toFixed(0));
    //     // //console.log(resp.data);
    //   })
    //   .catch((err) => console.log(err));

    // BancoService.getOcupacaoStorage(props.tokenLogin)
    //   .then((resp) => {
    //     setUsoStorage((resp.data.ocupacao / 1024).toFixed(1));
    //   })
    //   .catch((err) => console.log(err));

    // BancoService.getOcupacaoBanco(props.tokenLogin)
    //   .then((resp) => {
    //     setUsoBanco((resp.data.ocupacao / 1024).toFixed(1));
    //     setCarregando(false);
    //     // //console.log(resp.data);
    //   })
    //   .catch((err) => console.log(err));

    BancoService.getListaBancos(props.tokenLogin)
      .then((resp) => {
        UsuarioService.getListaUsuarios(props.tokenLogin)
          .then((resp2) => {
            const user = resp2.data.venda.usuarios.filter(
              (usuario) => usuario.email === props.usuario
            );

            let idBancoAtual = user[0]?.banco;
            let bancosDisponiveis = resp.data.bancos.bancos_disponiveis.map(
              (banco) => ({
                label: banco.nome,
                value: banco.uuid,
              })
            );
            let bancosIndisponiveis =
              resp.data.bancos.bancos_nao_disponiveis.map((banco) => ({
                label: banco.nome,
                value: banco.uuid,
                isDisabled: !props.isLeitor,
              }));

            let todosBancos = [...bancosDisponiveis, ...bancosIndisponiveis];

            let bancoAtual = todosBancos.find((b) => b.value === idBancoAtual);

            if (bancoAtual) {
              bancoAtual.isDisabled = false;

              setSelectedOption({
                label: bancoAtual?.label,
                value: bancoAtual?.value,
              });
            }
            setLoading(false);

            if (props.isLeitor) {
              setBancos(todosBancos);
              return;
            }

            setBancos([
              {
                label: "Disponiveis",
                options: bancoAtual
                  ? [bancoAtual, ...bancosDisponiveis]
                  : bancosDisponiveis,
              },
              {
                label: "Indisponíveis",
                options: bancosIndisponiveis?.filter(
                  (b) => b.value !== bancoAtual?.value
                ),
                color: "#d4d4d4",
              },
            ]);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        //console.log(err)
      });
  }, [props.tokenLogin, props.usuario]);

  function handleLoginClick() {
    props.doLogin();
  }

  function handleBancoChange(option) {
    setSelectedOption(option);
    // console.log(selectedOption);
    // console.log(option);
    if (selectedOption.value === option.value) return;

    let obj = {
      email: props.usuario,
      banco: option.value,
    };
    //console.log(obj);

    UsuarioService.alterarBancoUsuario(props.tokenLogin, obj)
      .then((resp) => {
        // console.log(resp);
        // console.log(resp.status);
        if (resp.status === 200) {
          store.addNotification({
            content: (
              <ToastNotification
                bg="primary"
                textoAuxiliar="Clique aqui para recarregar a página"
              >
                <b>Banco alterado com sucesso!</b> Necessário recarregar a
                página para aplicar a alteração{" "}
              </ToastNotification>
            ),
            onRemoval: (id, removedBy) => {
              // console.log(removedBy);
              if (removedBy === "click") {
                history.go(0);
              }
            },
            ...notificationTopRight,
          });
        } else {
          store.addNotification({
            content: (
              <ToastNotification bg="secondary" textoAuxiliar="">
                Não foi possível alterar o banco{" "}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => window.location.reload(true),
            ...notificationTopRight,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Não foi possível alterar o banco{" "}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => window.location.reload(true),
          ...notificationTopRight,
        });
      });
  }

  return (
    <ClickOutsideListener onClickOutside={props.closeMenu}>
      <PerfilMenu>
        <p className="user email">{props.usuario}</p>
        <Link to="/configuracoes">Configurações</Link>
        <a href="https://api.taxcelapp.com/docs/">Documentação API</a>
        <Armazenamento>
          <h5>
            Recompensas <GiftIcon />
          </h5>
          <a
            href="https://indique.taxcel.com.br/?bl_ref=Z163"
            target="_blank"
            rel="noopener noreferrer"
            className="indique"
            id="indique-taxcel-perfil"
          >
            Indique um amigo
          </a>
        </Armazenamento>
        {props.isContato && (
          <Armazenamento>
            <h5>Selecione a base de dados</h5>
            <div className="select">
              <Select
                id="banco-atual"
                name="banco-atual"
                isClearable={false}
                isSearchable={false}
                value={selectedOption}
                onChange={handleBancoChange}
                options={bancos}
                placeholder="Escolha seu banco"
                isLoading={isLoading}
                noOptionsMessage={() => "Nenhum banco encontrado"}
              />
            </div>
          </Armazenamento>
        )}
        {/* <Armazenamento>
          <h5>Uso de dados</h5>
          <p className="uso-arquivos">Arquivos armazenados:</p>
          <div className="uso">
            <ProgressBar
              progress={((usoStorage / tamanhoStorage) * 100).toFixed(0)}
            />
            {carregando ? (
              <span>Carregando...</span>
            ) : (
              <span>
                {usoStorage}GB de {tamanhoStorage}GB utilizados
              </span>
            )}
          </div>
          <p className="uso-arquivos">Arquivos carregados:</p>
          <div className="uso">
            <ProgressBar
              progress={((usoBanco / tamanhoBanco) * 100).toFixed(0)}
            />

            {carregando ? (
              <span>Carregando...</span>
            ) : (
              <span>
                {usoBanco}GB de {tamanhoBanco}GB utilizados
              </span>
            )}
          </div>
        </Armazenamento> */}
        <button onClick={handleLoginClick}>
          <PowerIcon className="icon" />
          <span>Sair</span>
        </button>
      </PerfilMenu>
    </ClickOutsideListener>
  );
}

export default TopnavPerfilMenu;
