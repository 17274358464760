import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import CarregadosIcon from "../images/carregados.svg";
import ArmazenadosIcon from "../images/armazenados.svg";

const UlDropdown = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  background: ${(props) => props.theme.primaryDark};
  padding: 0.75rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  /* The emerging W3C standard
   that is currently Firefox-only */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.primaryLight} ${(props) => props.theme.primaryDark};

  /* Works on Chrome/Edge/Safari */
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.primaryLight};
    border-radius: 20px;
    border: 2px solid ${(props) => props.theme.primary};
  }

  li {
    margin-left: 1rem;
    padding: 0.25rem;

    a {
      display: inline-block;
      width: 100%;
    }

    a:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    button {
      svg.icon {
        margin-right: 0.5rem;
        transform: translateY(1px);
      }

      img {
        display: inline-block;
        height: 20px;
        margin-right: 0.5rem;
        transform: translateY(4px);
        filter: invert(1);

        &.carregados {
          height: 24px;
          margin-top: -8px;
          transform: translateY(8px);
        }
      }
    }
  }
`;

const UploadMenu = styled.div`
  background: ${(props) => props.theme.primaryDark};
  color: ${(props) => props.theme.background};
  position: relative;
`;

const BancoMenu = styled.div`
  background: ${(props) => props.theme.primaryDark};
  color: ${(props) => props.theme.background};
  position: relative;
`;

export class UploadDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadMenu: false,
      bancoMenu: false,
    };
  }

  toggleUploadMenu = () => {
    this.setState({
      uploadMenu: !this.state.uploadMenu,
      bancoMenu: false,
    });
  };

  toggleBancoMenu = () => {
    this.setState({
      uploadMenu: false,
      bancoMenu: !this.state.bancoMenu,
    });
  };

  render() {
    return (
      <UlDropdown>
        <UploadMenu>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/icms"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("icms") ||
                  location.pathname.toLowerCase().endsWith("icms/")
                )
                  return true;
              }}
            >
              EFD-ICMS
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/contrib"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("contrib") ||
                  location.pathname.toLowerCase().endsWith("contrib/")
                )
                  return true;
              }}
            >
              EFD-CONTRIB
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/contabil"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("contabil") ||
                  location.pathname.toLowerCase().endsWith("contabil/")
                )
                  return true;
              }}
            >
              ECD
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/ecf"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("ecf") ||
                  location.pathname.toLowerCase().endsWith("ecf/")
                )
                  return true;
              }}
            >
              ECF
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/perdcomp"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("perdcomp") ||
                  location.pathname.toLowerCase().endsWith("perdcomp/")
                )
                  return true;
              }}
            >
              PERDCOMP
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/gia"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("gia") ||
                  location.pathname.toLowerCase().endsWith("gia/")
                )
                  return true;
              }}
            >
              GIA
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/dctf"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("dctf") ||
                  location.pathname.toLowerCase().endsWith("dctf/")
                )
                  return true;
              }}
            >
              DCTF
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat42"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat42") ||
                  location.pathname.toLowerCase().endsWith("cat42/")
                )
                  return true;
              }}
            >
              CAT-42
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat207"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat207") ||
                  location.pathname.toLowerCase().endsWith("cat207/")
                )
                  return true;
              }}
            >
              CAT-207
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cat83"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cat83") ||
                  location.pathname.toLowerCase().endsWith("cat83/")
                )
                  return true;
              }}
            >
              CAT-83
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfe_entrada"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfe_entrada") ||
                  location.pathname.toLowerCase().endsWith("nfe_entrada/")
                )
                  return true;
              }}
            >
              NF-e Entrada
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfe_saida"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfe_saida") ||
                  location.pathname.toLowerCase().endsWith("nfe_saida/")
                )
                  return true;
              }}
            >
              NF-e Saída/NFCe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/evento_nfe"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("evento_nfe") ||
                  location.pathname.toLowerCase().endsWith("evento_nfe/")
                )
                  return true;
              }}
            >
              Evento NFe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/nfse"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("nfse") ||
                  location.pathname.toLowerCase().endsWith("nfse/")
                )
                  return true;
              }}
            >
              NFSe
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cfe"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cfe") ||
                  location.pathname.toLowerCase().endsWith("cfe/")
                )
                  return true;
              }}
            >
              CF-e
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName="active"
              to="/storage/cte"
              isActive={(match, location) => {
                if (
                  location.pathname.toLowerCase().endsWith("cte") ||
                  location.pathname.toLowerCase().endsWith("cte/")
                )
                  return true;
              }}
            >
              CT-e
            </NavLink>
          </li>
        </UploadMenu>

        {/* <li>
          <button onClick={this.toggleBancoMenu}>
            <img className="carregados" src={CarregadosIcon} alt="ícone de arquivos carregados" /> Carregados
          </button>
        </li>
        {this.state.bancoMenu && (
          <BancoMenu>
            <li>
              <NavLink activeClassName="active" to="/database/icms">
                EFD-ICMS
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/contrib">
                EFD-CONTRIB
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/contabil">
                ECD
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/ecf">
                ECF
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/nfe_entrada">
                NF-e Entrada
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/nfe_saida">
                NF-e Saída
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/cfe">
                CF-e
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/database/cte">
                CT-e
              </NavLink>
            </li>
          </BancoMenu>
        )} */}
        {/* <li><NavLink activeClassName="active" to="/upload/icms">EFD-ICMS</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/contrib">EFD-CONTRIB</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/contabil">ECD</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/ecf">ECF</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/nfe-entrada">NF-e Entrada</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/nfe-saida">NF-e Saída</NavLink></li> */}
        {/* <li><NavLink activeClassName="active" to="/upload/cte">CT-e</NavLink></li>
                <li><NavLink activeClassName="active" to="/upload/cfe">CF-e</NavLink></li> */}
      </UlDropdown>
    );
  }
}
